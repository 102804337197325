body, html {
  margin: 0;
  padding: 0;
  background-color: #333645; /* Deep blue for a professional look */
  color: #fff; /* White text for contrast */
  font-family: 'Times New Roman', Times, serif; /* Professional Serif font */
  line-height: 1.6;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  height: 100%; /* Full height */
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure the app covers the full height of the viewport */
  padding: 2rem;
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.App-header {
  width: 100%; /* Ensure header spans the full width */
  text-align: center;
  margin-bottom: 1rem; /* Control spacing between title and content */
}

.App-logo {
  height: auto;
  width: 20%; /* Responsive width */
  max-width: 150px; /* Limit maximum size */
  margin-bottom: 1rem;
  border-radius: 10px; /* Rounded corners for the logo */
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem; /* Reduced space between title and text */
}

p {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  max-width: 600px; /* Max width for centered text */
  text-align: center;
}

button {
  background-color: #3949ab;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block; /* Ensure the button is block-level for margin centering */
  margin: 20px auto; /* Center button horizontally */
}

button:hover {
  background-color: #303f9f;
}

/* Specific styles for ConfirmationPage to hide header and logo */
.confirmation-page .App-header,
.confirmation-page .App-logo {
  display: none;
}

@media (max-width: 600px) {
  .App-logo {
    width: 40%; /* Adjust logo size for small screens */
  }

  h1 {
    font-size: 2rem; /* Smaller title on small screens */
  }
}
